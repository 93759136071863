
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
  
  {
    path: '/',
    redirect: '/home'
  }, {
    path: '/home',
    name: 'home',
    component: resolve => require(['@/view/HomePage'], resolve),
    meta: {
      title: '北京富通康影科技有限公司'
    }
  }, {
    path: '/product',
    name: 'product',
    component: resolve => require(['@/view/Product'], resolve),
    children: [
      {
        path: '/product',
        redirect: '/product/ct'
      },
      {
        path: '/product/ct',
        name: 'one',
        component: resolve => require(['@/view/ProductCt'], resolve),
        meta: {
          title: '技术和解决方案'
        }
      },
      {
        path: '/product/food_testing',
        name: 'one',
        component: resolve => require(['@/view/Product1'], resolve),
        meta: {
          title: '技术和解决方案'
        }
      },
      {
        path: '/product/security_check',
        name: 'one',
        component: resolve => require(['@/view/Product2'], resolve),
        meta: {
          title: '技术和解决方案'
        }
      },
      {
        path: '/product/industry',
        name: 'one',
        component: resolve => require(['@/view/Product3'], resolve),
        meta: {
          title: '技术和解决方案'
        }
      },
    ]
  }, {
    path: '/news',
    name: 'news',
    component: resolve => require(['@/view/NewsInformation'], resolve),
    meta: {
      title: '新闻中心'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: resolve => require(['@/view/CompanyIntroduction'], resolve),
    meta: {
      title: '公司介绍'
    }
  },
  {
    path: '/contactus',
    name: 'contactus',
    component: resolve => require(['@/view/ContactUs'], resolve),
    meta: {
      title: '联系我们'
    }
  },



]
const router = new VueRouter({
  
  base: process.env.BASE_URL,
  // (缩写) 相当于 routes: routes
  routes
})
export default router